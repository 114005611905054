:root {
  --transparent: rgba(255, 255, 255, 0);
  --white: #fff;
  --white2: #fcfcfc;
  --white3: #f1f1f1;

  --black: #000;
  --black2: #13101f;
  --black3: #444752;
  --black4: #2b2b29;
  --black5: #171616;
  --black6: #000000;
  --black7: #1f1f1f;
  --black8: #3e3d3f;
  --black9: #0f0f0f;
  --black10: #525252;
  --black11: #4b4b4b;
  --black12: #6B6D74;
  --black13: #111729;

  --black-opacity: rgba(0, 0, 0, 0.5);
  --black-opacity2: rgba(19, 16, 31, 0.45);
  --black-opacity3: rgba(40, 31, 61, 0.11);

  --gray: #f5efff;
  --gray2: #b4b0bb;
  --gray3: #d9d7dd;
  --gray4: #e5e4e8;
  --gray5: #c4c4c4;
  --gray6: #7a7283;
  --gray7: #97909e;
  --gray8: #7a7979;
  --gray9: #5a5a5a;
  --gray10: #f2f2f2;
  --gray11: #e1e1e1;
  --gray12: #eaeaea;
  --gray13: hsl(216, 14%, 46%);
  --gray14: #909090;
  --gray15: #e9e9e9;
  --gray16: #a3a3a3;
  --gray17: #647185;
  --gray18: #e3e3e3;
  --gray19: #bbbbbb;
  --gray20: #bebebe;
  --gray21: #8b9ba9;
  --gray22: #666666;
  --gray23: #e5e5e5;
  --gray24: #f7f7f7;
  --gray25: #626262;
  --gray26: #f8f8f8;
  --gray27: #f5f5f5;
  --gray28: #e8e8e8;
  --gray29: #f4f4f4;
  --gray30: #888888;
  --gray31: #f4f2f2;
  --gray32: #e1e6ef;
  --gray33: #ececec;
  --gray34: #dddddd;
  --gray35: #d1ced6;
  --gray36: #c7c4c4;
  --gray37: #eeeeee;
  --gray38: #e7e7e7;
  --gray39: #f3f3f3;
  --gray40: #d2d2d2;
  --gray41: #838383;
  --gray42: #66686f;
  --gray43: #c1c0c0;
  --gray44: #1b1b1b;
  --gray45: #ebebeb;

  --light-gray: #fbfbfb;
  --light-gray2: #E2E7E7;
  --light-gray3: #737B8B;

  --gray-opacity: rgba(40, 31, 61, 0.16);
  --gray-opacity2: rgba(244, 242, 247, 0.6);
  --gray-opacity3: rgba(68, 71, 82, 0.5);
  --gray-opacity4: rgba(114, 114, 114, 0.051);
  --gray-opacity5: rgba(0, 0, 0, 0.06);
  --gray-opacity6: rgba(206, 206, 206, 0.17);

  --blue: #364458;
  --blue2: #6c7584;

  --blue-opacity: rgba(40, 31, 61, 0.04);
  --blue-opacity2: rgba(40, 31, 61, 0.06);
  --blue-opacity3: rgba(212, 201, 236, 0.4);

  --red: #ed6c6c;
  --red2: #ffe4f4;

  --purple: #6b4ee6;
  --purple2: #c1b3f6;
  --purple3: #f0edfd;
  --purple4: #7a60e9;
  --purple5: #e1dcfa;
  --purple6: #f3f0fa;
  --purple7: #f4f1fa;
  --purple8: #eae4f6;
  --purple9: #bfafe3;
  --purple10: #dfd7f1;
  --purple11: #faf8fd;
  --purple12: #6246dc;
  --purple13: #9984f3;
  --purple14: #d8d0fc;
  --purple15: #fbfaff;
  --purple16: #927cf0;
  --purple17: #cabdff;
  --purple18: #7352ff;
  --purple19: #7454ff;
  --purple20: #8468f9;
  --purple21: #7b5cff;
  --purple22: #e7e2f5;
  --purple23: #a389ff;
  --purple24: #5433de;
  --purple25: #ddd4ff;
  --purple26: #8265ff;
  --purple27: #9880ff;
  --purple28: #ddd7f5;
  --purple29: #b7aaec;
  --purple30: #e8e6ef;
  --purple31: #eeeaff;
  --purple32: #b09dff;
  --purple33: #7a5cf6;
  --purple34: #c9bff1;
  --purple35: #fbfaff;
  --purple36: #e4dff4;

  --purple-opacity: rgba(250, 248, 253, 0.35);
  --purple-opacity2: rgba(86, 59, 142, 0.2);
  --purple-opacity3: rgba(250, 248, 253, 0.4);
  --purple-opacity4: rgba(240, 237, 253, 0.5);

  --pink: #d88dde;
  --pink2: #f9f8f9;

  --green: #79d0bb;
  --green2: #29d68d;
  --green3: #429984;

  --yellow: #f7c852;


  --slack-aubergine: #4a154b;
  --slack-black: #1d1c1d;
  --slack-white: #ffffff;
  --slack-green: #2eb67d;
}
