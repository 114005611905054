@font-face {
  font-family: 'Poppins';
  font-display: swap;
  font-style: normal;
  font-weight: 400;
  src:
    local('Poppins Regular'),
    local('Poppins-Regular'),
    url('/fonts/Poppins-Regular.woff2') format('woff2'),
    url('/fonts/Poppins-Regular.woff') format('woff');
}

@font-face {
  font-family: 'Poppins';
  font-display: swap;
  font-style: normal;
  font-weight: 500;
  src:
    local('Poppins Medium'),
    local('Poppins-Medium'),
    url('/fonts/Poppins-Medium.woff2') format('woff2'),
    url('/fonts/Poppins-Medium.woff') format('woff');
}

@font-face {
  font-family: 'Poppins';
  font-display: swap;
  font-style: normal;
  font-weight: 600;
  src:
    local('Poppins SemiBold'),
    local('Poppins-SemiBold'),
    url('/fonts/Poppins-SemiBold.woff2') format('woff2'),
    url('/fonts/Poppins-SemiBold.woff') format('woff');
}

@font-face {
  font-family: 'Poppins';
  font-display: swap;
  font-style: normal;
  font-weight: 700;
  src:
    local('Poppins Bold'),
    local('Poppins-Bold'),
    url('/fonts/Poppins-Bold.woff2') format('woff2'),
    url('/fonts/Poppins-Bold.woff') format('woff');
}