html {
  min-width: 320px;
  text-align: left;
  font-family: Poppins, sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 1.4;
  color: var(--black3);
  background: white;
  width: 100%;
  height: 100%;
}

body {
  position: relative;
  top: 0;
  left: 0;
  height: 100%;
}

#root {
  position: relative;
  top: 0;
  left: 0;
  height: 100%;
  margin: 0;
  overflow: hidden;
  -webkit-overflow-scrolling: auto;
}

 /* style for hidden text */
.visually-hidden {
  position: absolute !important;
  margin: 0 !important;
  padding: 0 !important;
  width: 1px !important;
  height: 1px !important;
  white-space: nowrap !important;
  clip-path: inset(100%) !important;
  clip: rect(0 0 0 0) !important;
  overflow: hidden !important;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus {
  border: 0;
  -webkit-text-fill-color: var(--black3);
  -webkit-box-shadow: 0 0 0 1000px transparent inset;
  transition: background-color 5000s ease-in-out 0s;
  background: -webkit-linear-gradient(
    top,
    rgba(255, 255, 255, 0) 0%,
    rgba(0, 174, 255, 0.04) 50%,
    rgba(255, 255, 255, 0) 51%,
    rgba(0, 174, 255, 0.03) 100%
  );
}

.Toastify__progress-bar--default {
  background: var(--purple) !important;
}

.text-line-clamp-1 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
}

.text-line-clamp-2 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}

.text-line-clamp-3 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
}

.text-line-clamp-9 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 9;
}

.primary-scrollbar::-webkit-scrollbar {
  width: 5px;
}
.primary-scrollbar::-webkit-scrollbar-track {
  background: var(--pink2);
  border-radius: 4px;
}
.primary-scrollbar::-webkit-scrollbar-thumb {
  background: var(--gray4);
  border-radius: 4px;
}
.primary-scrollbar::-webkit-scrollbar-thumb:hover {
  background: var(--purple2);
}

.gmnoprint a, .gmnoprint span, .gm-style-cc {
  display:none;
}

#detach-button-host {
  display: none;
}